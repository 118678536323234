<template>
  <div v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-5">
      <h4>{{ question.order }}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class="row">
      <div class="col-12 col col-md-8 offset-md-2 order-2">
        <div
          class="option shadow-sm"
          v-for="(option, i) in question.options" :key="i + 1">
          <div class="form-check">
            <input
              class="form-check-input checkbox"
              type="checkbox"
              :checked="result.some(x => x === option.order)"
              :id="`checkbox${option.order}`"
              @click="handleSelect(option.order)">
            <label class="form-check-label" :for="`checkbox${option.order}`"  style="cursor:pointer;">
              <p class="mx-5 mb-0">{{ option.text }}</p>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" :disabled="handleValidation" @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import {checkboxInitialData} from "../../utils/questionsInitialData";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

export default {
  name: "Checkbox",
  components: {},
  props: {},
  data() {
    return {
      question: checkboxInitialData,
      result: [],
    }
  },
  mounted() {
  },
  computed: {
    handleValidation() {
      return this.result.length < 1;
    }
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer
      }
    }
  },
  methods: {
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.result
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
    handleSelect(value) {
      if (this.result.some(x => x === value)) {
        const index = this.result.findIndex(x => x === value);
        this.result.splice(index, 1);
      } else {
        this.result.push(value);
      }
    }
  }

}
</script>

<style scoped lang="scss">

.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon   {
      fill: white ;
    }
  }
}
.checkbox {
  border-color: #23af4d !important;

  &:checked {
    background-color: #23af4d !important;
  }
}

.option {
  padding: 1rem;
  margin: 0.5rem 0;
  position: relative;
  border: 1px solid transparent;
}


.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}
</style>
